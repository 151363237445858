import { useEffect, useState } from "react";

import { BREAKPOINTS } from "@/constants";

const useBreakpoint = () => {
  const [currentBreakpoint, setCurrentBreakpoint] = useState(BREAKPOINTS.NONE);

  useEffect(() => {
    const queries = [];

    BREAKPOINTS.default.forEach((breakpoint, index) => {
      let mql;

      if (index === 0) {
        mql = window.matchMedia(`(min-width: ${breakpoint.value}px)`);
      } else {
        mql = window.matchMedia(
          `(min-width: ${breakpoint.value}px) and (max-width: ${
            BREAKPOINTS.default[index - 1].value - 1
          }px)`
        );
      }

      const listener = (e) => {
        if (e.matches) {
          setCurrentBreakpoint(breakpoint);
        }
      };

      mql.addEventListener("change", listener);

      if (mql.matches) {
        listener({ matches: true });
      }

      queries.push({
        listener,
        mql,
      });
    });

    return () => {
      queries.forEach((query) => {
        query.mql.removeEventListener("change", query.listener);
      });
    };
  }, []);

  return {
    currentBreakpoint,
    isDesktop: currentBreakpoint.value >= BREAKPOINTS.LG.value,
    isMobile: currentBreakpoint.value < BREAKPOINTS.MD.value,
    isTablet: currentBreakpoint.value === BREAKPOINTS.MD.value,
  };
};

export default useBreakpoint;
